import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Scan from '../views/Scan.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:trackingNo/:code',
    name: 'Home',
    component: Home
  },
  {
    path: '/scan/',
    name: 'Scan',
    component: Scan
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(rec => rec.meta.requiresAuth)){
    if(store.state.adminKey){
      next()
    } else {
      next({ name: 'Home' })
    }
  } else {
    next()
  }
})

export default router
