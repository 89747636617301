import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adminKey: null,
    membershipId: null
  },
  mutations: {
    updateAdminKey: (state, payload) => {
      state.adminKey = payload
      localStorage.adminKey = payload ? payload : ''
    },
    updateMembershipId: (state, payload) => {
      state.membershipId = payload
      localStorage.membershipId = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
