<template>
  <div class="columns is-gapless is-centered is-mobile">
    <div class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd mb-4">
      <div class="container container-modifier mt-5 mx-5">
        <p class="tracking-title mb-0">Scan</p>
        <hr class="mt-3">
        <div v-if="!pause">
          <StreamBarcodeReader
          @decode="onDecode"
          ></StreamBarcodeReader>
          <button 
            @click="pause = true"
            class="button is-fullwidth is-medium is-info mt-2"
            >
            Pause scan
          </button >
        </div>
        <div v-if="pause">
          <button 
            @click="pause = false"
            class="button is-fullwidth is-medium is-info mt-2"
            >
            Start scan
          </button >
        </div>
      </div>
    </div>
    <img class="home-back" src="../assets/home-back.4983bf91.png" alt="logo">
  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
import md5 from 'md5'

export default {
  name: 'Home',
  components: {
    StreamBarcodeReader
  },
  data() {
    return {
      pause: false
    }
  },
  methods: {
    onDecode(result){
      if(result){
        this.$router.push({
          name: 'Home',
          params: {
            trackingNo: result,
            code: md5(result + '23t90n89yn8m9on34jnmv89h2vt8944').substring(0,4)
          }
        })
      }
    }
  }
}
</script>

<style>
.home-back {
    margin: 0 auto;
    width: 100%;
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: -1;
}

.container-modifier {
    padding: 33px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
    margin-top: 5px;
    text-align: left;
}

.tracking-title {
    font-size: 17px;
    color: rgb(133, 137, 151);
    margin-bottom: 5px;
    font-family: Avenir !important;
}

.tracking-number {
    font-size: 25px;
    color: rgb(5, 5, 147);
    font-family: Avenir !important;
}
</style>