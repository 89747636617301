<template>
  <nav class="navbar navbar-shadow is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="columns is-gapless is-centered is-mobile mt-3 px-4">
      <div class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd">
        <nav class="level is-mobile px-0">
          <div class="level-left">
            <div class="level-item pb-2">
              <img class="janio-logo" src="../../assets/download.png">
            </div>
          </div>
          <div v-if="$store.state.adminKey" class="level-right">
            <div class="level-item is-clickable">
              <i @click="logout()" class="has-text-grey fas fa-sign-out-alt"></i>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'Navbar',
  methods: {
    logout() {
      this.$store.commit('updateAdminKey', null)
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>
.navbar {
  display: inherit !important;
}

.navbar .navbar-brand {
  text-align: center;
  display: block;
}

.navbar .navbar-brand > .navbar-item,
.navbar .navbar-brand .navbar-link {
  display: inline-block;
}

.navbar-brand-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53px;
}

.navbar-shadow {
    box-shadow: rgba(183, 190, 197, 0.51) 0px 2px 4px 0px;
}

.janio-logo {
    width: 79px;
    height: 37px;
}
</style>