<template>
  <div id="app" class="app">
    <Navbar/>
    <transition-page>
      <router-view/>
    </transition-page>
  </div>
</template>

<script>
import TransitionPage from './components/TransitionPage.vue'
import Navbar from '@/components/layout/Navbar'

export default {
  name: 'App',
  components: {
    TransitionPage,
    Navbar
  }
}
</script>


<style>
@import '../node_modules/bulma/css/bulma.css';

@font-face {
  font-family: Avenir; 
  src: url('./assets/fonts/AvenirLTStd-Heavy.ttf');
}

* {
  margin: 0;
  padding: 0;
}

.avenir {
  font-family: Avenir !important;
}
</style>
