import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag"
import VCalendar from 'v-calendar'
import { firestorePlugin } from 'vuefire'

Vue.use(VueGtag, {
  config: { id: 'G-E679CQ9FP4' },
  appName: 'Janio Contactless Portal',
  pageTrackerScreenviewEnabled: true
}, router)

Vue.use(VCalendar)
Vue.use(firestorePlugin)

Vue.config.productionTip = false

store.commit('updateAdminKey', localStorage.adminKey ? localStorage.adminKey : null)
store.commit('updateMembershipId', localStorage.membershipId ? localStorage.membershipId : null)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
