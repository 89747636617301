<template>
  <div class="columns is-gapless is-centered is-mobile">
    <div class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd mb-4">
      <div class="container container-modifier mt-5 mx-5">
        <p class="tracking-title mb-0">Tracking Number</p>
        <h1 class="tracking-number mb-0">{{ trackingNo }}</h1>
        <hr class="mt-3">
        <div v-if="state === 'PROCEED' && !viewOnly">
          <p class="tracking-title mb-2">Select an option and click 'Confirm':</p>
          <!-- <article class="message mb-2 is-clickable" v-bind:class="{'is-info': selectedIndex === 1}" @click="select(1)">
            <div class="message-body px-3">
              Wait for someone to answer the door.
            </div>
          </article> -->
          <article class="message mb-2 is-clickable" v-bind:class="{'is-info': selectedIndex === 2}" @click="select(2)">
            <div class="message-body px-3">
              Ring the doorbell & place the package directly outside the door. Courier will leave the location immediately.
            </div>
          </article>
          <article class="message mb-2 is-clickable" v-bind:class="{'is-info': selectedIndex === 3}" @click="select(3)">
            <div class="message-body px-3">
              Place the package at the building's official drop-off location, guard house, or school lockers and drop me a message.
            </div>
          </article>
          <article class="message mb-2 is-clickable" v-bind:class="{'is-info': selectedIndex === 4}" @click="select(4)">
            <div class="message-body px-3">
              Unable to receive the package today, please deliver on the next working day (Mon-Fri).
            </div>
          </article>
          <button 
            @click="setSelection()"
            class="button is-fullwidth is-medium mt-5"
            v-bind:class="{'is-info': selectedIndex && selectedIndex > 0, 'is-loading': loading}"
            :disabled="!selectedIndex"
            >
            Confirm
          </button >
        </div>
        <div v-if="state === 'PROCEED' && viewOnly">
          <p class="tracking-title mb-2">Consignee has not selected an option. Please carry out delivery as per normal.</p>
          <button 
            @click="goToScan()"
            class="button is-fullwidth is-medium is-info mt-5"
            >
            Scan another
          </button >
        </div>
        <div v-if="state === 'ALREADY_SET'">
          <p class="tracking-title mb-2">Selected for {{ selectedTimestamp }}:</p>
          <!-- <article v-if="selected === 'WAIT'" class="message is-info mb-2 is-clickable">
            <div class="message-body px-3">
              Wait for someone to answer the door.
            </div>
          </article> -->
          <article v-if="selected === 'OUTSIDE_DOOR'" class="message is-info mb-2 is-clickable">
            <div class="message-body px-3">
              Ring the doorbell & place the package directly outside the door. Courier will leave the location immediately.
            </div>
          </article>
          <article v-if="selected === 'DROPOFF_LOCATION'" class="message is-info mb-2 is-clickable">
            <div class="message-body px-3">
              Place the package at the building's official drop-off location, guard house, or school lockers and drop me a message.
            </div>
          </article>
          <article v-if="selected === 'NOT_TODAY'" class="message is-info mb-2 is-clickable">
            <div class="message-body px-3">
              Unable to receive the package today, please deliver on the next working day (Mon-Fri).
            </div>
          </article>
          <div class="is-size-7 mt-3 has-text-grey">
            Note: Our couriers will try our best to follow this instruction. If needed, we will contact you via call or message during the delivery process.
          </div>
          <button 
            @click="goToTracker()"
            class="button is-fullwidth is-medium is-info mt-5"
            >
            Track Package
            <i class="fas fa-xs fa-external-link-alt ml-2"></i>
          </button >
          <button
            v-if="state === 'PROCEED' && viewOnly"
            @click="goToScan()"
            class="button is-fullwidth is-medium is-info mt-1"
            >
            Scan another
          </button >
          <div @click="goToFaq()" class="mt-3 avenir has-text-centered has-text-grey is-clickable">
            For more assistance, please visit <u>tracker.janio.asia/faq</u>
          </div>
        </div>
      </div>
    </div>
    <img class="home-back" src="../assets/home-back.4983bf91.png" alt="logo">
  </div>
</template>

<script>
import { masterFunction } from '@/firebase/init'

export default {
  name: 'Home',
  data() {
    return {
      trackingNo: this.$route.params.trackingNo,
      code: this.$route.params.code,
      selectedIndex: null,
      loading: false,
      selected: null,
      selectedTimestamp: null,
      viewOnly: false,
      state: 'LOADING'
    }
  },
  methods: {
    viewResult() {
      this.loading = false
      this.state = 'LOADING'
      masterFunction({
        'methodName': 'view-results',
        'trackingNo': this.trackingNo,
        'code': this.code
      }).then(res => {
        if(res.data.message === 'Already SUCCESS.') {
          window.location.href = `https://tracker.janio.asia/${this.trackingNo}`
        } else if (res.data.message === 'Already set.') {
          this.selected = res.data.data.selection
          this.selectedTimestamp = (new Date(res.data.data.createdAt._seconds * 1000)).toLocaleDateString('en-SG')
          this.state = 'ALREADY_SET'
        } else if (res.data.message === 'trackingNo does not exist.') {
          this.viewOnly = res.data.viewOnly
          if(this.viewOnly) {
            alert(`Tracking Number ${this.trackingNo} not found under Janio.`)
            this.$router.push({
              name: 'Scan'
            })
          }
          this.state = 'NOT_EXIST'
        } else {
          this.viewOnly = res.data.viewOnly
          this.state = 'PROCEED'
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.state = 'INVALID'
        // window.location.href = `https://tracker.janio.asia/${this.trackingNo}`
        console.log(err)
      })
    },
    select(index) {
      if(this.selectedIndex !== index) {
        this.selectedIndex = index
      } else {
        this.selectedIndex = null
      }
    },
    setSelection() {
      if(this.selectedIndex && this.selectedIndex > 0) {
        this.loading = true
        masterFunction({
          'methodName': 'set-selection',
          'selectionIndex': this.selectedIndex-1,
          'trackingNo': this.trackingNo,
          'adminKey': this.code,
          'timezone': (new Date().getTimezoneOffset())/60*-1
        }).then(() => {
          this.loading = false
          this.viewResult()
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
      }
    },
    goToTracker() {
      window.location.href = `https://tracker.janio.asia/${this.trackingNo}`
    },
    goToScan() {
      this.$router.push({
        name: 'Scan'
      })
    },
    goToFaq() {
      window.location.href = 'https://tracker.janio.asia/faq'
    }
  },
  mounted() {
    this.viewResult()
  }
}
</script>

<style>
.home-back {
    margin: 0 auto;
    width: 100%;
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: -1;
}

.container-modifier {
    padding: 33px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
    margin-top: 5px;
    text-align: left;
}

.tracking-title {
    font-size: 17px;
    color: rgb(133, 137, 151);
    margin-bottom: 5px;
    font-family: Avenir !important;
}

.tracking-number {
    font-size: 25px;
    color: rgb(5, 5, 147);
    font-family: Avenir !important;
}
</style>