import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'

const prodConfig = {
    apiKey: "AIzaSyCaY1oBnR93ZaOK9M-3Z0KJAltis9SEZ50",
    authDomain: "inform-janio.firebaseapp.com",
    projectId: "inform-janio",
    storageBucket: "inform-janio.appspot.com",
    messagingSenderId: "818931243732",
    appId: "1:818931243732:web:134224edb28327d92f7941",
    measurementId: "G-E679CQ9FP4"
}

const firebaseApp = firebase.initializeApp(prodConfig)

export default firebaseApp

export const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')